import React from "react";

import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/expenses/Hero";
import Content1 from "../../sections/expenses/Content1";
import Content2 from "../../sections/expenses/Content2";
import CTA from "../../sections/expenses/CTA";
import Progress from "../../sections/expenses/Progress";

const Expenses = () => {
  return (
    <>
      <PageWrapper>
        <Hero className="pt-11 pt-lg-34 pb-8 pb-lg-33" />
        <Progress className="pb-lg-21 border-top border-default-color-1" />
        <Content1 className="pb-10 pb-sm-5 pb-lg-14" />
        <Content2 className="pt-23 pt-md-25 pt-lg-35 pb-9 pb-md-14 pb-lg-33 overflow-hidden position-relative" />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default Expenses;
