import React from "react";

import imgDashboard from "../../assets/image/inner-page/png/expenses-dashboard.png";
import imgDBG from "../../assets/image/inner-page/png/dot-bg.png";
import imgCS from "../../assets/image/inner-page/png/content-shape.png";

const Content1 = ({ className, ...rest }) => {
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          {/* <!-- Section Title --> */}
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-8">
              <div className="text-center mb-13 mb-lg-22 pr-lg-10 pr-xl-0">
                <h2 className="font-size-11 mb-0">
                  Menos papeleo y más productividad
                </h2>
              </div>
            </div>
          </div>
          {/* Section Title */}
          <div className="row justify-content-center">
            {/* Image Section */}
            <div className="col-lg-9 col-md-10 ">
              <div
                className="l2-content-image-group-1 w-100"
                data-aos="zoom-in"
                data-aos-delay={300}
                data-aos-once="true"
              >
                {/* Dashboard Img */}
                <img
                  className="dash w-100 shadow-9"
                  src={imgDashboard}
                  alt=""
                />
                {/* End Img-group-content */}
                <div className="img-3">
                  <img src={imgDBG} alt="" />
                </div>
                {/* End Dashboard Img */}
              </div>
            </div>
          </div>
        </div>
        <div className="full-width-shape w-100">
          <img className="w-100" src={imgCS} alt="" />
        </div>
      </div>
    </>
  );
};

export default Content1;
