import React from "react";
import { Link } from "gatsby";
import backgroundImage from "../../assets/image/inner-page/jpg/promo-bg-img.jpg";

const Content3 = ({ className, ...rest }) => {
  return (
    <>
      <div
        className={className}
        {...rest}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div
                className="text-center pt-14 pb-15 py-lg-31 dark-mode-texts"
                data-aos="zoom-in"
                data-aos-delay="500"
              >
                <h2 className="font-size-11 mb-11">La ayuda que necesitas</h2>
                <p className="font-size-7 line-height-28 px-md-10 px-lg-16 px-xl-25 mb-0">
                  Poder ayudar a tus empleados/as con sus incidencias de manera
                  rápida y eficaz mejorará la comunicación y confianza con la
                  empresa.
                </p>
                <Link to="/contact" className="btn btn-blue-3 rounded-5 mt-12">
                  Contacta con nosotros
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content3;
