import React from "react";
import { Link } from "gatsby";

import imgSBR from "../../assets/image/inner-page/png/round-shape-blue.png";
import imgBP from "../../assets/image/inner-page/png/dot-pattern-blue.png";
import imgH from "../../assets/image/inner-page/png/incidences-hero-1.png";
import imgH2 from "../../assets/image/inner-page/png/incidences-hero-2.png";
import imgCard from "../../assets/image/inner-page/png/card-expenses.png";

const Hero = ({ className, ...rest }) => {
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          <div className="row justify-content-center">
            {/* <!-- Hero Content --> */}
            <div
              className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1"
              data-aos="fade-right"
              data-aos-delay="500"
            >
              <div className="pr-lg-10 pr-xl-0 pt-0 pt-lg-0 pb-13 pb-lg-0">
                <h6 className="font-size-5 text-blue-1 mb-5 mb-lg-9 font-weight-normal">
                  Hojas de gastos
                </h6>
                <h1 className="font-size-12 mb-8">
                  Una solución para todos tus gastos
                </h1>
                <p className="font-size-7 mb-0 heading-default-color">
                  Gestiona y digitaliza todos los gastos de tus empleados/as.
                  Certificados por la Agencia Tributaria.
                </p>
                <div className=" pt-5 pt-lg-10">
                  <Link to="/contact" className="btn btn-blue-3 rounded-5">
                    Saber más
                  </Link>
                  <span className="d-block pt-5 pt-lg-9 heading-default-color">
                    También puedes enviarnos un email
                    <a
                      className="text-blue-3 btn-link-with-underline ml-1"
                      href="mailto:hola@timeview.io"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ¡Escríbenos!
                    </a>
                  </span>
                </div>
              </div>
            </div>
            {/* <!-- End Hero Content --> */}
            {/* <!-- l2-hero-image-group --> */}
            <div
              className="col-xl-7 col-lg-6 col-md-7 order-1 order-lg-2"
              data-aos="fade-left"
              data-aos-delay="800"
            >
              <div className="l2-hero-image-group mt-5 mt-lg-0">
                <div className="img-1">
                  <img src={imgSBR} alt="" />
                </div>
                <div className="img-2">
                  <img src={imgBP} alt="" />
                </div>
                <div className="img-3">
                  <img src={imgH} alt="" />
                </div>
                <div className="img-4">
                  <img src={imgH2} alt="" />
                </div>
                <div className="img-5">
                  <img src={imgCard} alt="" />
                </div>
              </div>
            </div>
            {/* <!-- End l2-hero-image-group --> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
